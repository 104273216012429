import React from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = ({ handleLogout }) => {
    const history = useNavigate();

    const handleLogoutClick = () => {
        localStorage.clear(); // Clear all items in localStorage
        handleLogout();
        history.push('/'); // Redirect to the home page
    };

    return (
        <a href="" onClick={handleLogoutClick}>
            LOGOUT
        </a>
    );
};

export default Logout;
