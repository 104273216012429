import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ContactSection2.module.css';

const ContactSection2 = () => {
    const navigate = useNavigate();

    const handleOrderNowClick = () => {
        navigate('/order-now');
    };

    return (
        <div className={styles.container}>
            <div className={styles.map}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7003.110535712716!2d77.13218456881282!3d28.643088288611164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0381dd6ade4b%3A0xf2784412c76eb350!2sCake%20company!5e0!3m2!1sen!2sin!4v1725642089930!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
            <div className={styles.info}>
                <h2>We Are Open</h2>
                <p><strong>Cake Company</strong></p>
                <p>Monday to Sunday</p>
                <p>24 X 7</p>
                <button className={styles.orderButton} onClick={handleOrderNowClick}>ORDER NOW</button>
            </div>
        </div>
    );
};

export default ContactSection2;
