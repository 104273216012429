import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Section2.module.css';

const flavors = [
    { name: 'Chocolate', category: 'Chocolate_Cakes' },
    { name: 'Fruits', category: 'Fruit_Cakes' },
    { name: 'Red Velvet', category: 'Red_Velvet_Cakes' },
    { name: 'Rose', category: 'Rose_Cakes' },
    { name: 'Blackforest', category: 'Black_Forest_Cakes' },
    { name: 'Pineapple', category: 'Pineapple_Cakes' },
    { name: 'Butterscotch', category: 'Butterscotch_Cakes' },
    { name: 'Vanilla', category: 'Vanilla_Cakes' },
];

const Section2 = () => {
    const navigate = useNavigate();

    const handleFlavorClick = (category) => {
        navigate(`/order-now?category=${category}`);
    };

    return (
        <section className={styles.section2}>
            <h2>Experience Flavours</h2>
            <div className={styles.grid}>
                {flavors.map((flavor, index) => (
                    <div 
                        key={index} 
                        className={styles.flavorBox} 
                        onClick={() => handleFlavorClick(flavor.category)}
                    >
                        {flavor.name}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Section2;
