import React from 'react';
import styles from './Section7.module.css';
import { useNavigate } from 'react-router-dom';

const images = [
    { src: "https://bkmedia.bakingo.com/gourmet_cake_desktop%20(1)_1.jpg", category: 'Gourmet_Cakes' },
    { src: "https://bkmedia.bakingo.com/birthday_cake_desktop.jpg", category: 'Birthday_Photo_Cakes' },
    { src: "https://bkmedia.bakingo.com/jungle_cake_desktop_0.jpg", category: 'Jungle_Theme_Cake' },
    { src: "https://bkmedia.bakingo.com/regular-cake-desktop_2.jpg", category: 'Chocolate_Cakes' }
];

const Section7 = () => {
    const navigate = useNavigate();
    const handleImageClick = (category) => {
        navigate(`/order-now?category=${category}`);
    };

    return (
        <section className={styles.sectionSeven}>
            <h2 className={styles.sectionSevenTitle}>Explore Our Special Cakes</h2>
            <div className={styles.imageContainer}>
                {images.map((image, index) => (
                    <div key={index} className={styles.imageSlide} onClick={() => handleImageClick(image.category)}>
                        <img src={image.src} alt={image.category} className={styles.image} />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Section7;