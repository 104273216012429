import React, { useState, useEffect } from 'react';
import styles from './Section4.module.css';

const reviews = [
    {
        text: "I am sharing my experience with cake company someone send gift me cake from cake company cake is awesome quality fresh and superb 5 star quality I eat this type of quality in 5 stars hotel at very high price range",
        author: "A three Sq"
    },
    {
        text: "Booked a Birthday cake for my partner in the last minute. These guys took the order late night and delivered a beautiful and yummy cake the very next day as per the request. The theme was executed exactly how I wanted to and the person made time and efforts to discuss what had in mind. Recommend everyone to give a try at least once and you'll become a fan just like I did. Can't wait to order for upcoming celebrations ☺️✨",
        author: "Shilaka Addyya"
    },
    {
        text: "The cake was fantastic, the bread was fresh, the cream was fresh, the taste was just amazing!❤️ The cake came in proper shape, very well decorated. The way of handling customer is also amazing, they were very polite and kind to me. And they assured me with their cake, and I was amazeddd! Definitely give them a try.🌸",
        author: "Anupma Sharma"
    }
];

const Section4 = () => {
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
        }, 5000); // Change review every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
        <div className={styles.section}>
            <div className={styles.reviewContainer}>
                <h2>CUSTOMER REVIEWS</h2>
                <p className={styles.reviewText}><i>“{reviews[currentReviewIndex].text}"</i></p>
                <p className={styles.reviewAuthor}>{reviews[currentReviewIndex].author}</p>
            </div>
            
        </div>
    );
};

export default Section4;
