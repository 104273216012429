import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Section1.module.css';

const images = [
    { src: "https://bkmedia.bakingo.com/photo_pulling_cake_desktop.jpg", category: 'Photo_Pulling_Cakes' },
    { src: "https://bkmedia.bakingo.com/pinata_cake_desktop.jpg", category: 'Pinata_Cakes' },
    { src: "https://bkmedia.bakingo.com/explosion-birthday-bomb-photo-cake-bomb2718vani-A.jpg", category: 'Bomb_Cakes' },
    { src: "https://bkmedia.bakingo.com/pull_me_up_cake_desktop.jpg", category: 'Pull_Me_Up_Cakes' },
    { src: "https://bkmedia.bakingo.com/sq-birthday-box-of-cake-and-personalised-pictures-surprise2584-A_0.jpg", category: 'Surprise_Cakes' },
    { src: "https://bkmedia.bakingo.com/theme_cake_9.jpg", category: 'Jungle_Theme_Cake' },
    { src: "https://bkmedia.bakingo.com/gourmet_cake_0.jpg", category: 'Gourmet_Cakes' },
    { src: "https://bkmedia.bakingo.com/first-birthday-mickey-cake-theme4225flav-AA.jpg", category: 'Cartoon_Cakes' },
    
];

const Section1 = () => {
    const navigate = useNavigate();

    const handleImageClick = (category) => {
        navigate(`/order-now?category=${category}`);
    };

    const handleViewAllClick = () => {
        navigate(`/order-now`);
    };

    return (
        <section className={styles.section1}>
            <h2>Trending Cakes</h2>
            <div className={styles.grid}>
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={styles.imageWrapper}
                        onClick={() => handleImageClick(image.category)}
                    >
                        <img src={image.src} alt={`Trending Cake ${index + 1}`} className={styles.image} />
                        <p>{image.category.replace(/_/g, ' ')}</p>
                    </div>
                ))}
            </div>
            <button onClick = {handleViewAllClick} className={styles.viewAllButton}>View All</button>
        </section>
    );
};

export default Section1;
