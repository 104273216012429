import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Section3.module.css';

const images = [
    { src: "https://bkmedia.bakingo.com/barbie_1.jpg", category: 'Barbie_Cakes' },
    { src: "https://bkmedia.bakingo.com/cartoon-cake_0.jpg", category: 'Cartoon_Cakes' },
    { src: "https://bkmedia.bakingo.com/number-cake.jpg", category: 'Number_Cakes' },
    { src: "https://bkmedia.bakingo.com/rainbow_cakes_desktop.jpg", category: 'Rainbow_Cakes' },
    { src: "https://bkmedia.bakingo.com/jungle-theme-cake.jpg", category: 'Jungle_Theme_Cake' },
    { src: "https://bkmedia.bakingo.com/baby-shower_0.jpg", category: 'Baby_Shower_Cakes' }
];

const Section3 = () => {
    const navigate = useNavigate();

    const handleImageClick = (category) => {
        navigate(`/order-now?category=${category}`);
    };

    const handleViewAllClick = () => {
        navigate(`/order-now`);
    };

    return (
        <section className={styles.section3Container}>
            <h2 className={styles.section3Title}>Designer Cakes</h2>
            <div className={styles.section3Grid}>
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={styles.section3ImageWrapper}
                        onClick={() => handleImageClick(image.category)}
                    >
                        <img src={image.src} alt={image.category.replace(/_/g, ' ')} className={styles.section3Image} />
                        <p className={styles.section3ImageLabel}>{image.category.replace(/_/g, ' ')}</p>
                    </div>
                ))}
            </div>
            <button onClick={handleViewAllClick} className={styles.section3ViewAllButton}>View All</button>
        </section>
    );
};

export default Section3;
