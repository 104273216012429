import React, { useEffect, useState } from 'react';
import styles from './OrderDetail.module.css';
import axiosInstance from '../../api';
import vegIcon from '../Images/veg.jpg'; // Adjust path as needed
import nonvegIcon from '../Images/nonveg.jpg'; // Adjust path as needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../Constant/DateUtils';

const OrderDetail = ({ orderNumber, onClose }) => {
    const [orderDetails, setOrderDetails] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isDeliveryBoy, setIsDeliveryBoy] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupType, setPopupType] = useState(''); // 'confirm', 'otp', or 'delivery'
    const [popupMessage, setPopupMessage] = useState('');
    const [confirmAction, setConfirmAction] = useState(null);
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');

    useEffect(() => {
        const role = localStorage.getItem('role');
        setIsAdmin(role === '1');
        setIsDeliveryBoy(role === '3');
        fetchOrderDetails(orderNumber);
    }, [orderNumber]);

    useEffect(() => {
        if (popupType === 'delivery') {
            fetchUsers();
        }
    }, [popupType]);

    const fetchOrderDetails = (orderNumber) => {
        setLoading(true);
        axiosInstance.get(`${process.env.REACT_APP_ORDER_DETAIL}/${orderNumber}`)
            .then(response => {
                setOrderDetails(response.data.responseObject);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching order details:', error);
                setLoading(false);
            });
    };

    const fetchUsers = () => {
        axiosInstance.post(`${process.env.REACT_APP_USER}`, [{ key: "ROLE", value: "3" }])
            .then(response => {
                setUsers(response.data.responseObject.content.map(user => ({ id: user.id, fullName: user.fullName })));
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    };

    const handleStatusUpdate = (status, otp = '') => {
        setLoading(true); // Show loader when starting the API call
        const payload = {
            orderNumber: orderDetails.orderNumber,
            status: status,
            ...(status === 3 && selectedUserId ? { userId: selectedUserId } : {}),
            ...((status === 4 || status === 8) && otp ? { otp: otp } : {}),
        };

        axiosInstance.post(`${process.env.REACT_APP_ORDER_STATUS_UPDATE}`, payload)
            .then(response => {
                setOrderDetails({ ...orderDetails, orderStatus: getOrderStatusText(status) });
                setShowPopup(false);
                if (status === 4 || status === 8 || status === 10 || status === 5) {
                    onClose();
                    window.location.reload(); // Refresh the page
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error updating order status:', error.response.data.responseMessage);
                if (status === 4 || status === 8 || status === 10) {
                    setOtpError(error.response.data.responseMessage);
                }
                setLoading(false);
            });
    };

    const getOrderStatusText = (status) => {
        switch (status) {
            case 2:
                return 'ORDER CONFIRMED';
            case 3:
                return 'OUT FOR DELIVERY';
            case 5:
                return 'CANCELLED';
            case 4:
                return 'DELIVERED';
            case 7:
                return 'READY TO PICK';
            case 8:
                return 'ORDER PICKED';
            case 9:
                return 'ORDER PREPARED';
            case 10:
                return 'ORDER SERVED';
            default:
                return orderDetails?.orderStatus || 'UNKNOWN STATUS';
        }
    };

    const handleCancel = () => {
        setPopupMessage('Are you sure you want to cancel this order?');
        setConfirmAction(() => () => handleStatusUpdate(5));
        setPopupType('confirm');
        setShowPopup(true);
    };

    const handleConfirm = () => {
        if (orderDetails.orderStatus === 'ORDER RECEIVED') {
            setPopupMessage('Are you sure you want to confirm this order?');
            setConfirmAction(() => () => handleStatusUpdate(2));
        } else if (orderDetails.orderStatus === 'ORDER CONFIRMED' && orderDetails.orderType === 1) {
            setPopupMessage('Are you sure you want to mark this order as out for delivery?');
            setConfirmAction(() => () => handleStatusUpdate(3));
            setPopupType('delivery'); // Show delivery user dropdown
        } else if (orderDetails.orderStatus === 'ORDER CONFIRMED' && orderDetails.orderType === 2) {
            setPopupMessage('Are you sure you want to mark this order as ready to pick?');
            setConfirmAction(() => () => handleStatusUpdate(7));
            setPopupType('confirm');
        } else if (orderDetails.orderStatus === 'READY TO PICK' && orderDetails.orderType === 2) {
            setPopupMessage('Are you sure you want to mark this order as picked?');
            setConfirmAction(() => () => handleStatusUpdate(8));
            setPopupType('otp');
        } else if (orderDetails.orderStatus === 'ORDER CONFIRMED' && orderDetails.orderType === 3) {
            setPopupMessage('Are you sure you want to mark this order as prepared?');
            setConfirmAction(() => () => handleStatusUpdate(9));
            setPopupType('confirm');
        } else if (orderDetails.orderStatus === 'ORDER PREPARED' && orderDetails.orderType === 3) {
            setPopupMessage('Are you sure you want to mark this order as served?');
            setConfirmAction(() => () => handleStatusUpdate(10));
            setPopupType('confirm');
        }
        else if (orderDetails.orderStatus === 'OUT FOR DELIVERY' || orderDetails.orderStatus === 'READY TO PICK') {
            if (isAdmin || isDeliveryBoy) {
                setPopupMessage('Please enter the OTP provided by the customer:');
                setConfirmAction(() => handleOTPSubmit);
                setPopupType('otp');
            }
        }
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
        setOtp('');
        setOtpError('');
        setSelectedUserId('');
    };

    const handleOTPChange = (e) => {
        setOtp(e.target.value);
    };

    const handleOTPSubmit = () => {
        if (otp.length === 4 && orderDetails.orderType === 1) {
            handleStatusUpdate(4, otp);
        } else if (otp.length === 4 && orderDetails.orderType === 2) {
            handleStatusUpdate(8, otp);
        } else {
            setOtpError('OTP must be 4 digits');
        }
    };

    const handleUserChange = (e) => {
        setSelectedUserId(e.target.value);
    };

    if (loading) {
        return (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} spin size="3x" />
            </div>
        );
    }

    if (!orderDetails) {
        return (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} spin size="3x" />
            </div>
        );
    }

    const handleGetDirections = () => {
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${orderDetails.latitude},${orderDetails.longitude}`;
        window.open(googleMapsUrl, '_blank'); // Opens Google Maps in a new tab
    };

    const handlePrintInvoice = () => {
        const orderNumber = orderDetails.orderNumber;
        axiosInstance.post(`${process.env.REACT_APP_PRINT_INVOICE}?orderNumber=${orderNumber}`)
            .then(response => {
                if (response.data && response.data.responseStatusCode === 200) {
                    const { billUrl } = response.data.responseObject;
                    const printWindow = window.open(billUrl, '_blank');
                    printWindow.onload = function () {
                        printWindow.print(); // Automatically trigger the print dialog
                    };
                } else {
                    console.log('Invoice generation failed: No data received');
                }

            })
            .catch(error => {
                console.log('Invoice generation failed: No data received');
            });
    }

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.cartContainer}>
                <div className={styles.cartHeader}>
                    <h2>#{orderDetails.orderNumber}</h2>
                    <button className={styles.closeButton} onClick={onClose}>×</button>
                </div>
                <div className={styles.orderStatus}>
                    <p>Order Type: {orderDetails.orderTypeDesc}</p>
                    <p>Status: {orderDetails.orderStatus}</p>
                    <p>Payment Mode: {orderDetails.paymentMode}</p>
                    <p>Payment Status: {orderDetails.paymentStatus}</p>
                    <p>Order Date: {formatDate(orderDetails.createdOn)}</p>
                    {orderDetails.scheduleDate && <p>Schedule Date: {formatDate(orderDetails.scheduleDate)}</p>}
                    {orderDetails.orderType === 1 && orderDetails.deliveredOn && <p>Delivered Date : {formatDate(orderDetails.deliveredOn)}</p>}
                    {orderDetails.orderType === 2 && orderDetails.deliveredOn && <p>Picked On : {formatDate(orderDetails.deliveredOn)}</p>}
                    {orderDetails.orderType === 3 && orderDetails.deliveredOn && <p>Served On : {formatDate(orderDetails.deliveredOn)}</p>}
                    {(isAdmin || isDeliveryBoy) && orderDetails.orderStatus !== 'CANCELLED' && orderDetails.orderStatus !== 'DELIVERED' && orderDetails.orderStatus !== 'ORDER PICKED' && orderDetails.orderStatus !== 'ORDER SERVED' && (
                        <div>
                            <button onClick={handleCancel}>Cancel</button>
                            {(orderDetails.orderStatus === 'ORDER RECEIVED' || orderDetails.orderStatus === 'ORDER CONFIRMED' || orderDetails.orderStatus === 'OUT FOR DELIVERY' || orderDetails.orderStatus === 'READY TO PICK' || orderDetails.orderStatus === 'ORDER PREPARED') && (
                                <button onClick={handleConfirm}>
                                    {orderDetails.orderStatus === 'ORDER RECEIVED' && 'Confirm'}
                                    {(orderDetails.orderStatus === 'ORDER CONFIRMED' && orderDetails.orderType === 1) && 'Out for Delivery'}
                                    {(orderDetails.orderStatus === 'ORDER CONFIRMED' && orderDetails.orderType === 2) && 'Ready to Pick'}
                                    {(orderDetails.orderStatus === 'ORDER CONFIRMED' && orderDetails.orderType === 3) && 'Order Prepared'}
                                    {(orderDetails.orderStatus === 'READY TO PICK' && orderDetails.orderType === 2) && 'Picked'}
                                    {(orderDetails.orderStatus === 'ORDER PREPARED' && orderDetails.orderType === 3) && 'Served'}
                                    {(orderDetails.orderStatus === 'OUT FOR DELIVERY' && orderDetails.orderType === 1) && 'Deliver'}
                                </button>
                            )}
                        </div>
                    )}
                    {(orderDetails.orderStatus !== 'CANCELLED' && orderDetails.orderStatus !== 'ORDER RECEIVED') &&
                        <button onClick={handlePrintInvoice}>Print Invoice</button>
                    }
                </div>

                {orderDetails.additionalMessage && <div className={styles.additionalMessage}>Additional Instructions : {orderDetails.additionalMessage}</div>}

                {(isAdmin || isDeliveryBoy) && (<div className={styles.addressSection}>
                    <div><h2>Customer Info</h2>
                        <p>Name: {orderDetails.customerName}</p>
                        <p>Mobile Number: {orderDetails.customerMobileNumber}</p></div>
                </div>)}
                {(orderDetails.orderType === 1) && <div className={styles.addressSection}>
                    <div>
                        <h2>Delivery Address</h2>
                        <p>{orderDetails.address}</p>
                        {orderDetails.landmark && <p><b>Landmark:</b> {orderDetails.landmark}</p>}
                        {orderDetails.pincode && <p><b>Pincode:</b> {orderDetails.pincode}</p>}
                        {(isAdmin || isDeliveryBoy) && (orderDetails.latitude && orderDetails.longitude) && <button className={styles.getDirectionsButton} onClick={handleGetDirections}>
                            Get Directions
                        </button>}
                    </div>
                </div>
                }
                <div className={styles.orderItems}>
                    <h2>Order Items</h2>
                    {orderDetails.orderItems.map((item, index) => (
                        <div key={index} className={styles.orderItem}>
                            <img
                                src={item.foodType === 'VEG' ? vegIcon : nonvegIcon}
                                alt={item.foodType}
                                className={styles.foodTypeIcon}
                            />
                            <div className={styles.itemInfo}>
                                <p>
                                    <strong>
                                        {item.quantity} x {item.itemName}
                                        {item.variant ? ` (${item.variant})` : ''}
                                    </strong>
                                </p>

                                <p>₹{item.totalPrice}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.paymentDetails}>
                    <h2>Bill Summary</h2>
                    <div>
                        <span>Subtotal:</span>
                        <span>₹{orderDetails.subtotal}</span>
                    </div>
                    <div>
                        <span>Delivery charges:</span>
                        <span>₹{orderDetails.shippingCharges}</span>
                    </div>
                    <div>
                        <span>Taxes & other charges:</span>
                        <span>₹{orderDetails.totalTax}</span>
                    </div>
                    {(orderDetails.couponCode && orderDetails.discount) && <div>
                        <span>Discount ({orderDetails.couponCode})</span>
                        <span>-₹{orderDetails.discount}</span>
                    </div>}
                    <div className={styles.totalAmount}>
                        <span>Total Amount:</span>
                        <span>₹{orderDetails.totalAmount}</span>
                    </div>
                </div>
                {showPopup && (
                    <div className={styles.popupOverlay}>
                        <div className={styles.popup}>
                            <p>{popupMessage}</p>
                            {popupType === 'otp' ? (
                                <div>
                                    <input
                                        type="number"
                                        value={otp}
                                        onChange={handleOTPChange}
                                        placeholder="Enter OTP"
                                        className={styles.otpInput}
                                    />
                                    {otpError && <p className={styles.error}>{otpError}</p>}
                                    <button onClick={handleOTPSubmit}>Submit</button>
                                    <button onClick={closePopup}>Cancel</button>
                                </div>
                            ) : popupType === 'delivery' ? (
                                <div>
                                    <select value={selectedUserId} onChange={handleUserChange} className={styles.userDropdown}>
                                        <option value="">Select Delivery User (Optional)</option>
                                        {users.map(user => (
                                            <option key={user.id} value={user.id}>
                                                {user.fullName}
                                            </option>
                                        ))}
                                    </select>
                                    <button onClick={() => handleStatusUpdate(3)}>Out for Delivery</button>
                                    <button onClick={closePopup}>Cancel</button>
                                </div>
                            ) : (
                                <div>
                                    <button onClick={confirmAction}>Confirm</button>
                                    <button onClick={closePopup}>Cancel</button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrderDetail;