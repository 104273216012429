// src/components/PrivacyPolicy.js
import React from 'react';
import styles from './PrivacyPolicy.module.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MetaTags from '../MetaTags';

const PrivacyPolicy = () => {
    return (
        <div>
             <MetaTags title="Privacy Policy | Cake Company"/>
            <Header />
            <div className={styles.ppSection}>
                <div className={styles.ppOverlay}></div>
                <div className={styles.ppText}>PRIVACY POLICY</div>
            </div>
            <div className={styles.container}>

                <h2 className={styles.sectionTitle}>1. Information We Collect</h2>

                <h3 className={styles.subsectionTitle}>1.1 Personal Information</h3>
                <p className={styles.sectionContent}>
                    We may collect personal information that you provide directly to us when you:
                    <ul>
                        <li>Create an account</li>
                        <li>Place an order</li>
                        <li>Contact us or request information</li>
                        <li>Subscribe to our newsletter</li>
                    </ul>
                    Personal information may include, but is not limited to:
                    <ul>
                        <li>Name</li>
                        <li>Email address</li>
                        <li>Phone number</li>
                        <li>Shipping and billing address</li>
                        <li>Payment information</li>
                    </ul>
                </p>

                <h3 className={styles.subsectionTitle}>1.2 Usage Data</h3>
                <p className={styles.sectionContent}>
                    We may also collect information about your interactions with the Site, including:
                    <ul>
                        <li>IP address</li>
                        <li>Browser type</li>
                        <li>Pages visited</li>
                        <li>Time spent on pages</li>
                        <li>Referring website</li>
                    </ul>
                </p>

                <h3 className={styles.subsectionTitle}>1.3 Cookies and Tracking Technologies</h3>
                <p className={styles.sectionContent}>
                    We use cookies and similar tracking technologies to enhance your experience on our Site, analyze usage, and customize content. You can manage your cookie preferences through your browser settings.
                </p>

                <h2 className={styles.sectionTitle}>2. How We Use Your Information</h2>
                <p className={styles.sectionContent}>
                    We use your information to:
                    <ul>
                        <li>Process and fulfill your orders</li>
                        <li>Improve our Site and services</li>
                        <li>Communicate with you, including sending order confirmations and promotional offers</li>
                        <li>Respond to your inquiries and provide customer support</li>
                        <li>Analyze and understand usage patterns to enhance user experience</li>
                    </ul>
                </p>

                <h2 className={styles.sectionTitle}>3. How We Share Your Information</h2>
                <p className={styles.sectionContent}>
                    We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following cases:
                    <ul>
                        <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our Site and conducting our business, such as payment processors and delivery services.</li>
                        <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to valid requests by public authorities (e.g., a subpoena).</li>
                        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our business, your information may be transferred as part of that transaction.</li>
                    </ul>
                </p>

                <h2 className={styles.sectionTitle}>4. Data Security</h2>
                <p className={styles.sectionContent}>
                    We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                </p>

                <h2 className={styles.sectionTitle}>5. Your Rights and Choices</h2>
                <p className={styles.sectionContent}>
                    You have the right to:
                    <ul>
                        <li>Access, correct, or delete your personal information</li>
                        <li>Opt-out of receiving marketing communications from us</li>
                        <li>Request information about the data we hold about you</li>
                    </ul>
                    To exercise these rights, please contact us at contact@cakecompany.in.
                </p>

                <h2 className={styles.sectionTitle}>6. Third-Party Links</h2>
                <p className={styles.sectionContent}>
                    Our Site may contain links to third-party websites. We are not responsible for the privacy practices or content of such third parties. We encourage you to review the privacy policies of any third-party sites you visit.
                </p>

                <h2 className={styles.sectionTitle}>7. Children’s Privacy</h2>
                <p className={styles.sectionContent}>
                    Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.
                </p>

                <h2 className={styles.sectionTitle}>8. Changes to This Privacy Policy</h2>
                <p className={styles.sectionContent}>
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
