import React, { useEffect, useRef } from "react";
import Footer from "../Constant/Footer/Footer";
import Header from "../Constant/Header/Header";
import Section2 from "../Constant/Section2/Section2";
import Section1 from "../Constant/Section1/Section1";
import Section4 from "../Constant/Section4/Section4";
import Section5 from "../Constant/Section5/Section5";
import Section6 from "../Constant/Section6/Section6";
import styles from './Home.module.css';
import Section3 from "../Constant/Section3/Section3";
import Section7 from "../Constant/Section7/Section7";
import MetaTags from "../Constant/MetaTags";

const Home = () => {
  const homeRef = useRef(null);

  useEffect(() => {
    if (homeRef.current) {
      homeRef.current.classList.add(styles.bounceIn); // Change to any animation class you choose
    }
  }, []);

  return (
    <div>
      <MetaTags title="Best Cakes in Delhi | Cake Company"/>
      <Header />
      <Section7 />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      {/* <Section6 /> */}
      <Footer />
    </div>
  );
};

export default Home;
