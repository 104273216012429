// MetaTags.js
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const MetaTags = ({ title }) => {
  return (
    <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content="Order the best cakes online with free delivery. Check out our wide range of cakes available in various flavors!" />
          <meta name="keywords" content="cakes, buy cakes online, birthday cakes, best cakes" />
          <meta name="robots" content="index, follow" />
          {/* Open Graph Meta Tags (for Social Sharing) */}
          <meta property="og:title" content={{title}} />
          <meta property="og:description" content="Order delicious cakes with free delivery!" />
          <meta property="og:url" content="https://cakecompany.in/" />
        </Helmet>
      </HelmetProvider>
  );
};

export default MetaTags;