import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Section5.module.css';

const categories = [
    { src: "https://bkmedia.bakingo.com/pastries_3.png", category: 'Pastries' },
    { src: "https://bkmedia.bakingo.com/jar_cakes_2.png", category: 'Jar_Cakes' },
    { src: "https://bkmedia.bakingo.com/cup_cakes_5.png", category: 'Cup_Cakes' },
    { src: "https://bkmedia.bakingo.com/brownies_2.png", category: 'Brownies' },
    { src: "https://bkmedia.bakingo.com/hampers.png", category: 'Gift_Hampers' },
    { src: "https://bkmedia.bakingo.com/cookies-desktop.png", category: 'Cookies' },
];

const Section5 = () => {
    const navigate = useNavigate();

    const handleImageClick = (category) => {
        navigate(`/order-now?category=${category}`);
    };

    return (
        <section className={styles.sectionFive}>
            <h2 className={styles.sectionFiveTitle}>Looking For Something Else</h2>
            <div className={styles.sectionFiveGrid}>
                {categories.map((image, index) => (
                    <div
                        key={index}
                        className={styles.imageWrapperFive}
                        onClick={() => handleImageClick(image.category)}
                    >
                        <img src={image.src} alt={image.category} className={styles.imageFive} />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Section5;
