
import styles from './Variant.module.css';

const Variant = ({ cartItems, item, handleQuantityChange, onClose }) => {
    // Get quantities for each variant from cartItems
    const getVariantQuantity = (variantId) => {
        const quantity = cartItems
            .filter(
                (cartItem) =>
                    cartItem.itemId === item.item.id &&
                    cartItem.variantId === variantId
            )
            .reduce((total, cartItem) => total + cartItem.quantity, 0);

        return quantity;
    };

    const getTotalItemQuantity = () => {
        return item.item.variants.reduce(
            (total, variant) => total + getVariantQuantity(variant.id),
            0
        );
    };

    // Handle increment quantity for a specific variant
    const handleIncrement = (variantId) => {
        handleQuantityChange(item.item.id, 1, item.categoryName, variantId);
    };

    // Handle decrement quantity for a specific variant
    const handleDecrement = (variantId) => {
        handleQuantityChange(item.item.id, -1, item.categoryName, variantId);
    };

    return (
        <div className={styles.variantPopup}>
            <div className={styles.variantPopupContent}>
                <img
                    src={item.item.imageUrl}
                    alt={item.item.item}
                    className={styles.itemImage}
                />
                <h2 className={styles.variantHeading}>Select Variant for {item.item.item}</h2>
                <div className={styles.variantOptions}>
                    {item.item.variants.map((variant) => (
                        <div key={variant.id} className={styles.variantOption}>
                            <span className={styles.variantName}>{variant.variant}</span>
                            <div className={styles.priceContainer}>
                                {(variant.originalPrice) && <span className={styles.originalPrice}>₹{variant.originalPrice}</span>}
                                <span className={styles.discountPrice}>₹{variant.discountPrice}</span>
                            </div>
                            <div className={styles.quantityContainer}>
                                <button className={styles.variantButton}>
                                    <div
                                        className={styles.decrementButton}
                                        onClick={() => handleDecrement(variant.id)}
                                    >
                                        -
                                    </div>
                                </button>
                                <span className={styles.quantityDisplay}>
                                    {getVariantQuantity(variant.id)}
                                </span>
                                <button className={styles.variantButton}>
                                    <div
                                        className={styles.incrementButton}
                                        onClick={() => handleIncrement(variant.id)}
                                    >
                                        +
                                    </div>
                                </button>

                            </div>
                        </div>
                    ))}
                </div>
                <button className={styles.closeButton} onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default Variant;