import React from 'react';
import styles from './AboutSection2.module.css';

const AboutSection2 = () => {
    return (
        <div className={styles.aboutSection}>
            <div className={styles.heading}>
                Our Story </div>
            <div className={styles.paragraph}>
                Cake company is FSSAI certified & one of the leading company created in 2019. We are based in New Delhi & known for our handcrafted products. Our all sort of eggless cakes are crafted in our owned base kitchen. Cake company products are pure & hygienic with all the goodsome of home baking & nothing artificial added.
            </div>
            <div className={styles.subheading}>Our Journey so far</div>
            <div className={styles.paragraph}>
                Cake Company is owned by the professionals who undertook their chef training in one of the most reputed Hotel Industry in 2012 in New Delhi i.e. THE ASHOK & TAJ Group restaurants & continued working in Hospitality group for 7 years. After receiving various accolades in their field, we decided to start our own venture with the name of Cake Company where our prime agenda to serve our customers a quality product with great taste & great customer service. So, in 2019, we founded CAKE COMPANY and set about creating high quality eggless cakes & we serve both type of customers (Corporate & Retail).</div>

        </div>
    );
};

export default AboutSection2;
