import React from 'react';
import styles from './ContactSection3.module.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaYoutube } from 'react-icons/fa';

const ContactSection3 = () => {
    return (
        <div className={styles.container}>
            <div className={styles.location}>
                <h2>Our Location</h2>
                <p><FaMapMarkerAlt /> B-33 DSIDC Complex, Kirti Nagar Industrial Area, New Delhi, 110015 </p>
                <a href="https://maps.app.goo.gl/ZupYffmcjaviMvTH6" target="_blank" rel="noopener noreferrer" className={styles.direction}>Get Direction</a>
            </div>
            <div className={styles.contact}>
                <h2>Contact Us</h2>
                <div className={styles.paragraph}>
                <p><FaPhone /> +91 9625104721</p>
                </div>
                <div className={styles.paragraph}>
                <p><FaPhone /> +91 7011048903 </p>
                </div>
                <div className={styles.paragraph}>
                <p><FaEnvelope /> <a href="mailto:contact@cakecompany.in">contact@cakecompany.in</a></p>
                </div>
                
                <div className={styles.socialMedia}>
                    <a href="https://www.youtube.com/@simran_cakecompany" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
                </div>
            </div>
        </div>
    );
};

export default ContactSection3;
