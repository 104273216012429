import React, { useEffect, useState } from 'react';
import styles from './Orders.module.css';
import Header from '../Constant/Header/Header';
import Footer from '../Constant/Footer/Footer';
import axiosInstance from '../../api'; // Adjust path as needed
import vegIcon from '../Images/veg.jpg'; // Adjust path as needed
import nonvegIcon from '../Images/nonveg.jpg'; // Adjust path as needed
import OrderDetail from './OrderDetail'; // Adjust path as needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../Constant/DateUtils';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCustomer, setIsCustomer] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [shouldFetchOrders, setShouldFetchOrders] = useState(false);

    useEffect(() => {
        const role = localStorage.getItem('role');
        setIsCustomer(role === '2'); // Check if role is 2 (Customer)
        setIsAdmin(role === '1');
    }, []);

    useEffect(() => {
        setOrders([]);
        setPageNumber(0);
        setTotalPages(0);
        fetchOrders(0);
    }, [searchQuery]); // Removed selectedStatus

    useEffect(() => {
        if (shouldFetchOrders) {
            fetchOrders(0);
            setShouldFetchOrders(false); // Reset after fetching
        }
    }, [shouldFetchOrders]);

    useEffect(() => {
        fetchOrders(pageNumber);
    }, [pageNumber]);

    const toggleFilterPopup = () => {
        setIsFilterPopupOpen(!isFilterPopupOpen);
    };

    const handleApplyFilters = () => {
        setOrders([]);
        setPageNumber(0);
        setShouldFetchOrders(true); // Set state to trigger fetch
        setIsFilterPopupOpen(false);
    };

    const handleClearFilters = () => {
        setSelectedStatus(null);
        setOrders([]);
        setPageNumber(0);
        fetchOrders(0);
        setIsFilterPopupOpen(false);
    };

    const fetchOrders = (page = 0) => {
        setIsLoading(true);

        const payload = [
            { key: "ORDER_NUMBER", value: searchQuery }
        ];

        if (selectedStatus) {
            payload.push({ key: "STATUS", value: selectedStatus });
        }

        axiosInstance.post(`${process.env.REACT_APP_ORDER_LIST}/?pageNumber=${page}&pageSize=20&direction=DESC`, payload)
            .then(response => {
                if (response.data && response.data.responseObject && response.data.responseObject.content) {
                    const newOrders = response.data.responseObject.content;

                    setOrders(prevOrders => page === 0 ? newOrders : [...prevOrders, ...newOrders]);

                    setTotalPages(response.data.responseObject.totalPages);
                } else {
                    console.error('Invalid response format or missing content array');
                }
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchClick = () => {
        setOrders([]);
        setPageNumber(0);
        fetchOrders(0);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
        setOrders([]);
        setPageNumber(0);
        fetchOrders(0);
    };

    const handleOrderClick = (orderNumber) => {
        setIsLoading(true);
        setSelectedOrder(orderNumber);
        setTimeout(() => {
            setIsPopupOpen(true);
            setIsLoading(false);
            document.body.classList.add(styles.noScroll);
        }, 300);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedOrder(null);
        document.body.classList.remove(styles.noScroll);
    };

    const Pagination = () => {
        const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

        return (
            <div className={styles.pagination}>
                {pages.map(page => (
                    <button
                        key={page}
                        className={`${styles.pageButton} ${page === pageNumber + 1 ? styles.activePage : ''}`}
                        onClick={() => setPageNumber(page - 1)}
                    >
                        {page}
                    </button>
                ))}
            </div>
        );
    };

    return (
        <div>
            <Header />
            <div className={styles.orderSection}>
                <div className={styles.orderOverlay}></div>
                <div className={styles.orderText}>MY ORDERS</div>
            </div>
            <div className={styles.ordersContainer}>
                <div className={styles.searchContainer}>
                    <input
                        type="text"
                        placeholder="Search order number"
                        className={styles.searchBox}
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                    />
                    <button onClick={handleSearchClick} className={styles.searchButton}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                    {searchQuery && (
                        <button onClick={handleClearSearch} className={styles.clearButton}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    )}
                    {isAdmin && <button onClick={toggleFilterPopup} className={styles.filterButton}>
                        <FontAwesomeIcon icon={faFilter} />
                    </button>}
                </div>
                {isFilterPopupOpen && (
                    <div className={styles.filterPopupOverlay}>
                        <div className={styles.filterPopup}>
                            <button className={styles.closeButton} onClick={toggleFilterPopup}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <h3>Filters</h3>
                            <div className={styles.filterSection}>
                                <p>Status</p>
                                <label>
                                    <input type="radio" name="status" value="1" onChange={() => setSelectedStatus(1)} />
                                    Order Received
                                </label>
                                <label>
                                    <input type="radio" name="status" value="2" onChange={() => setSelectedStatus(2)} />
                                    Order Confirmed
                                </label>
                                <label>
                                    <input type="radio" name="status" value="3" onChange={() => setSelectedStatus(3)} />
                                    Out for delivery
                                </label>
                                <label>
                                    <input type="radio" name="status" value="4" onChange={() => setSelectedStatus(4)} />
                                    Delivered
                                </label>
                                <label>
                                    <input type="radio" name="status" value="5" onChange={() => setSelectedStatus(5)} />
                                    Cancelled
                                </label>
                            </div>
                            <div className={styles.filterActions}>
                                <button onClick={handleClearFilters}>Clear All</button>
                                <button onClick={handleApplyFilters}>Apply</button>
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.orderList}>
                    {orders.map(order => (
                        <div key={order.id} className={styles.orderCard} onClick={() => handleOrderClick(order.orderNumber)}>
                            <div className={styles.orderHeader}>
                                <h3>#{order.orderNumber}</h3>
                                <p className={styles.orderStatus}>{order.orderStatus}</p>
                            </div>
                            <div className={styles.orderDetails}>
                                {order.orderItems.map(item => (
                                    <div key={item.id} className={styles.orderItem}>
                                        <img src={item.foodType === 'VEG' ? vegIcon : nonvegIcon} alt={item.foodType} className={styles.foodTypeIcon} />
                                        <p>
                                            <strong>
                                                {item.quantity} x {item.itemName}
                                                {item.variant ? ` (${item.variant})` : ''}
                                            </strong>
                                        </p>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.orderFooter}>
                                <p><strong>Total: ₹{order.totalAmount}</strong></p>
                            </div>
                            <div className={styles.orderDate}>
                                Date : {formatDate(order.createdOn)}
                            </div>
                            {isCustomer && <div className={styles.otp}>OTP : {order.otp}</div>}
                        </div>
                    ))}
                </div>
                <Pagination />
            </div>
            {isLoading && (
                <div className={styles.loader}>
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                </div>
            )}
            {isPopupOpen && <OrderDetail orderNumber={selectedOrder} onClose={handleClosePopup} />}
            <Footer />
        </div>
    );
};

export default Orders;
